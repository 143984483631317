import React, { FC } from 'react';

import Form from 'react-bootstrap/Form';

import '../SignUpForm.scss';
import { ISignUpFormInput } from '../models';

const SignUpFormInput: FC<ISignUpFormInput> = ({
  onChange,
  name,
  value,
  pattern,
  label,
  validationMessage,
  controlId,
}) => {
  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <Form.Group controlId={controlId}>
      <Form.Label className="required">{label}</Form.Label>
      <Form.Control
        type="text"
        size="lg"
        pattern={pattern}
        required
        value={value}
        name={name}
        onChange={handleChange}
      />
      <Form.Control.Feedback type="invalid">{validationMessage}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default SignUpFormInput;
