import React, { FC } from 'react';
import { useId } from 'react-id-generator';

import { IRadioButtonCustom } from './models';
import './ControledCheckboxCustom.scss';

const ControledCheckboxCustom: FC<IRadioButtonCustom> = ({
  onChange,
  children,
  name,
  value,
  checked,
}) => {
  const [dropId] = useId();

  const handleChange = (event) => {
    onChange(event);
  };

  return (
    <label className="checkbox-custom" htmlFor={`checkbox-custom-${dropId}`}>
      <input
        className="checkbox-custom__input"
        type="checkbox"
        onChange={handleChange}
        id={`checkbox-custom-${dropId}`}
        name={name}
        checked={checked}
        value={value}
      />
      <span className="checkbox-custom__icon" />
      {children}
    </label>
  );
};

export default ControledCheckboxCustom;
