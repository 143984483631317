import React, { FC } from 'react';

import Form from 'react-bootstrap/Form';

import CheckBoxCustom from 'components/common/CheckBoxCustom';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import '../SignUpForm.scss';

const SignUpFormConsent: FC<NewsletterSignUp.ConsentTextboxes> = ({
  consentIntro,
  consentValidationMessage,
  consentLabel,
  discountInfo,
}) => {
  return (
    <div className="dt-sign-up-form__bottom-block">
      <DangerouslySetInnerHtml className="dt-sign-up-form__text" html={consentIntro} />
      <Form.Group className="required">
        <CheckBoxCustom checked={false} isRequired validationMsg={consentValidationMessage}>
          <DangerouslySetInnerHtml html={consentLabel} />
        </CheckBoxCustom>
      </Form.Group>
      <DangerouslySetInnerHtml html={discountInfo} className="dt-sign-up-form__text" />
    </div>
  );
};

export default SignUpFormConsent;
