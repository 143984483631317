import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SignUpForm from 'components/SignUpForm';
import Layout from 'components/Layout';
import Banner from 'components/common/Banner';

import 'styles/main.scss';
import './NewsletterSignUpPage.scss';

type TProps = {
  data: INewsletterSignUpPageData;
};

interface INewsletterSignUpPageData extends PageContent.IDefaultLayoutProps {
  newsletterSignUp: NewsletterSignUp.Page;
}

const NewsletterSignUpPage: FC<TProps> = ({
  data: {
    newsletterSignUp,
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  },
}) => {
  const {
    seoNoIndex,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoCanonicalUrl,
    seoExternalHreflangs,
    seoImage,
    ogPageType,
    signUpBtnLabel,
    signUpBanner: [{ regularTitle, boldTitle, image, descriptionText }],
    personalInformationTitle,
    personalInformation,
    cleaningHabitsTitle,
    cleaningHabits,
    consentTextboxes,
    businessDocumentData,
    thankYouMsg,
    thankYou,
    link,
  } = newsletterSignUp;

  return (
    <Layout
      seo={{
        seoNoIndex,
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
        ogPageType,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      <section className="dt-sign-up">
        <Banner
          subTitle={regularTitle}
          title={{ boldText: boldTitle }}
          text={descriptionText}
          img={image}
        />
        <Container fluid>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <GoogleReCaptchaProvider
                reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
                scriptProps={{ async: true, defer: true, appendTo: 'body' }}
              >
                <SignUpForm
                  personalInformationTitle={personalInformationTitle}
                  cleaningHabitsTitle={cleaningHabitsTitle}
                  cleaningHabits={cleaningHabits}
                  personalInformation={personalInformation}
                  consentTextboxes={consentTextboxes}
                  signUpBtnLabel={signUpBtnLabel}
                  businessDocumentData={businessDocumentData}
                  thankYouMsg={thankYouMsg}
                  thankYou={thankYou}
                />
              </GoogleReCaptchaProvider>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query NewsletterSignUpPageQuery($lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    newsletterSignUp(lang: { eq: $lang }) {
      link
      businessDocumentData {
        agreements {
          BusinessId
          RevisionId
          ConsentAcceptedInd
          ConsentDesc
          MandatoryInd
        }
      }
      cleaningHabits {
        inline
        options
        structure
        title
        validationMessage
      }
      cleaningHabitsTitle
      consentTextboxes {
        consentIntro
        consentLabel
        consentValidationMessage
        discountInfo
        structure
      }
      personalInformation {
        label
        structure
        validationMessage
      }
      personalInformationTitle
      seoMetaKeywords
      signUpBanner {
        boldTitle
        descriptionText
        image {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        imageAlt
        regularTitle
        structure
      }
      seoNoIndex
      seoMetaDescription
      seoMetaTitle
      seoMetaKeywords
      seoCanonicalUrl
      seoImage
      ogPageType
      seoExternalHreflangs {
        key
        value
      }
      signUpBtnLabel
      thankYouMsg
      thankYou {
        properties {
          textClose
          title
          textDiscount
          code
          textCopy
          textBeforeBtn
          textAfterBtn
          link {
            name
            url
            target
          }
          logo {
            url
          }
          logoAlt
          ariaCopy
          ariaBuy
        }
      }
    }
  }
`;

export default NewsletterSignUpPage;
