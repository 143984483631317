import React, { FC, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Overlay from 'components/common/Overlay';
import IconCustom from 'components/common/IconCustom';
import ControledCheckboxCustom from 'components/common/ControledCheckboxCustom';

import tracking from 'services/tracking';
import { gtmService } from 'services/gtmService';

import SignUpFormInput from './SignUpFormInput/SignUpFormInput';
import ThankYouContent from './ThankYouContent/ThankYouContent';
import SignUpFormConsent from './SignUpFormConsent/SignUpFormConsent';

import { ISignUpForm, ISignUpValues } from './models';

import './SignUpForm.scss';

const EMAIL_REGEXP =
  '^(?!.*\\.\\.)[a-zA-Z0-9_.!#$()\\\\%&’*+/=?^`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$';
const NAME_REGEXP = '^[a-zA-Z]([\\w -]*[a-zA-Z\\s*]){1,}?$';

const SignUpForm: FC<ISignUpForm> = ({
  signUpBtnLabel,
  personalInformationTitle,
  personalInformation,
  cleaningHabitsTitle,
  cleaningHabits,
  consentTextboxes,
  businessDocumentData,
  thankYou,
}) => {
  const {
    siteSettings: { siteUrl },
  } = useStaticQuery(graphql`
    {
      siteSettings {
        siteUrl
      }
    }
  `);

  const cleaningHabitsInitials = cleaningHabits.reduce((acc, item) => {
    const obj = item.options.reduce(
      (a, opt) => {
        return {
          ...a,
          [opt]: false,
        };
      },
      { invalid: 0 }
    );

    return {
      ...acc,
      [item.structure]: obj,
    };
  }, {});

  const [loading, setLoading] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [formState, setFormState] = useState<{ submitted: boolean; errorMsg?: string }>({
    submitted: false,
    errorMsg: '',
  });
  const [signUpValues, setSignUpValues] = useState<ISignUpValues>({
    name: '',
    email: '',
    ...cleaningHabitsInitials,
  } as ISignUpValues);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formRef = useRef<HTMLDivElement>(null);

  const handleChange = (event) => {
    event.persist();

    setSignUpValues((values) => {
      if (event.target.type === 'checkbox') {
        return {
          ...values,
          [event.target.name]: {
            ...values[event.target.name],
            [event.target.value]: event.target.checked,
          },
        };
      }

      return {
        ...values,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleClose = () => {
    setFormState({
      submitted: false,
      errorMsg: '',
    });
  };

  const formatOption = (opt) => {
    return opt.toLowerCase().replace(/ /g, '_');
  };

  const formatCleaningHabits = () => {
    return cleaningHabits.reduce((acc, curItem) => {
      const cleaningHabit: { question: string } = { question: '' };
      cleaningHabit.question = curItem.title.replace(/ /g, '_').replace(/\?/g, '');
      curItem.options.map((option) => {
        cleaningHabit[formatOption(option)] = signUpValues[curItem.structure][option] || false;

        return cleaningHabit;
      });
      acc.push(cleaningHabit);

      return acc;
    }, [] as { question: string }[]);
  };

  const formIsCompleted = () =>
    Object.keys(signUpValues).reduce((verdict, structure) => {
      if (typeof signUpValues[structure] !== 'string') {
        const groupVerdict = Object.values(signUpValues[structure]).some((option) => option);

        setSignUpValues((values) => {
          return {
            ...values,
            [structure]: {
              ...values[structure],
              invalid: groupVerdict ? 0 : 1,
            },
          };
        });

        return verdict && groupVerdict;
      }

      return true;
    }, true);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (!form.checkValidity() || !formIsCompleted()) {
      event.stopPropagation();
      if (formRef) {
        formRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      if (!executeRecaptcha) {
        return;
      }
      const gRecaptchaResponse = await executeRecaptcha('newsletterSignUpSubmit');
      const body = {
        recaptchaResponse: gRecaptchaResponse,
        cdp: {
          programCode: process.env.GATSBY_CDP_PROGRAM_CODE,
          brandOrgCode: process.env.GATSBY_CDP_BRAND_ORG_CODE,
          accountSource: process.env.GATSBY_CDP_ACCOUNT_SOURCE,
        },
        data: {
          // provide hardcoded TierCode as a fallback in order to keep backward compatibility
          TierCode: process.env.GATSBY_CDP_TIER_CODE || 'RBGBRDETTIER1',
          Emails: [
            {
              EmailAddress: signUpValues.email,
              DeliveryStatus: 'G',
            },
          ],
          JsonExternalData: {
            UnmappedAttributes: {
              email: signUpValues.email,
              name: signUpValues.name,
              TCs: true,
              marketingOptin: true,
              brand: businessDocumentData?.[0]?.brand,
              market: businessDocumentData?.[0]?.market,
              fromURL: siteUrl,
              campain: 'newsletter',
              time: businessDocumentData?.[0]?.time,
              CleaningHabits: formatCleaningHabits(),
            },
            Agreements: businessDocumentData?.map((doc) => doc?.agreements[0]),
          },
        },
      };
      setLoading(true);

      tracking.trackKrux(signUpValues.email, 'signupForm');

      const response = await fetch(process.env.GATSBY_CDP_LAMBDA_URL!, {
        method: 'POST',
        body: JSON.stringify(body),
      });
      if (response.status >= 200 && response.status <= 299) {
        await response.json();
        setLoading(false);
        setFormState({
          submitted: true,
          errorMsg: '',
        });
        gtmService.emitGenerateLead(gtmService.formNames.newsletter);
      } else {
        setLoading(false);
        setFormState({
          submitted: false,
          errorMsg: `${response.status} ${response.statusText}`,
        });
      }
    }
    setValidated(true);
  };

  if (!formState.submitted) {
    return (
      <Form className="dt-sign-up-form" noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="dt-sign-up-form__form-row" ref={formRef}>
          <strong className="dt-sign-up-form__row-title">{personalInformationTitle}</strong>
          <div className="dt-sign-up-form__two-column">
            {personalInformation.map((input) => (
              <SignUpFormInput
                label={input.label}
                name={input.structure.toLowerCase()}
                value={input.structure === 'Email' ? signUpValues.email : signUpValues.name}
                onChange={handleChange}
                pattern={input.structure === 'Email' ? EMAIL_REGEXP : NAME_REGEXP}
                validationMessage={input.validationMessage}
                controlId={`dt-sign-up-form__form-${input.structure.toLowerCase()}`}
                key={input.label}
              />
            ))}
          </div>
        </div>

        <div className="dt-sign-up-form__form-row">
          <strong className="dt-sign-up-form__row-title">{cleaningHabitsTitle}</strong>
          {cleaningHabits.map((item) => {
            const listClasses = cx('dt-sign-up-form__radio-list', {
              'list-inline': !!Number(item.inline),
            });

            return (
              <Form.Group key={item.structure} data-testid={item.structure}>
                <strong className="dt-sign-up-form__list-title">
                  <span className="required">{item.title}</span>
                </strong>
                {validated && signUpValues[item.structure].invalid === 1 && (
                  <div className="invalid-feedback d-block">{item.validationMessage}</div>
                )}
                <ul className={listClasses}>
                  {item.options.map((option) => (
                    <li className={cx({ 'list-inline-item': !!Number(item.inline) })} key={option}>
                      <ControledCheckboxCustom
                        label={option}
                        name={item.structure}
                        value={option}
                        checked={signUpValues[item.structure][option]}
                        onChange={handleChange}
                      >
                        <span dangerouslySetInnerHTML={{ __html: option }} />
                      </ControledCheckboxCustom>
                    </li>
                  ))}
                </ul>
              </Form.Group>
            );
          })}
        </div>

        <SignUpFormConsent {...consentTextboxes[0]} />

        <div className="text-center invalid-feedback d-block">{formState.errorMsg}</div>
        <div className="text-center">
          <Button variant="primary" type="submit">
            {signUpBtnLabel}
          </Button>
        </div>
        <Overlay visible={loading} />
      </Form>
    );
  }

  return thankYou?.length ? (
    <Modal
      dialogClassName="dt-sign-up-form__modal"
      show={formState.submitted}
      onHide={handleClose}
      centered
      size="l"
    >
      <Modal.Body>
        <ThankYouContent thankYou={thankYou} />
      </Modal.Body>
      <Modal.Footer>
        <Button className="dt-sign-up-form__modal-close" variant="link" onClick={handleClose}>
          <IconCustom icon="close_X_icon" />
          {thankYou[0].properties.textClose}
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
};

export default SignUpForm;
