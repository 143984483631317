import sha256 from 'sha256';

declare global {
  interface Window {
    dataLayer: object[];
    Krux: (
      namespace: string,
      consent: string,
      errorHandler: (err: any) => void,
      data: { [key: string]: boolean }
    ) => void;
  }
}

const trackKrux = (email: string, usage: string) => {
  if (!email.trim()) {
    return;
  }

  const emailHash = sha256(email.trim().toLowerCase());
  window.dataLayer && window.dataLayer.push({ event: 'new_subscriber', email_sha256: emailHash });

  typeof window.Krux === 'function' &&
    window.Krux(
      'ns:reckittbenckiserukhealth',
      'page:load',
      (err) => {
        console.error(`Krux at ${usage} has an error: ${err}`);
      },
      { pageView: true }
    );
};

export default {
  trackKrux,
};
