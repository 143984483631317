import React, { FC, useRef } from 'react';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import Button from 'react-bootstrap/Button';
import IconCustom from 'components/common/IconCustom';
import { IThankYouProps } from '../models';
import './ThankYouContent.scss';

const ThankYouContent: FC<IThankYouProps> = ({
  thankYou: [
    {
      properties: {
        title,
        textDiscount,
        code,
        textBeforeBtn,
        textAfterBtn,
        link,
        logo,
        logoAlt,
        ariaBuy,
        ariaCopy,
        textCopy,
      },
    },
  ],
}) => {
  const promoCodeInput = useRef<HTMLInputElement>(null);

  const copyToClipboard = () => {
    promoCodeInput?.current?.select();
    document.execCommand('copy');
  };

  return (
    <div className="thank-you-success">
      <div className="thank-you-success__group">
        {title ? <DangerouslySetInnerHtml html={title} /> : null}
        {textDiscount ? (
          <DangerouslySetInnerHtml className="thank-you-success__highlighted" html={textDiscount} />
        ) : null}
      </div>
      {code ? (
        <div className="thank-you-success__group thank-you-success__code">
          <input
            ref={promoCodeInput}
            className="thank-you-success__code-input"
            type="text"
            readOnly
            value={code}
          />
          <Button
            aria-label={ariaCopy}
            onClick={copyToClipboard}
            className="thank-you-success__copy"
            variant="link"
          >
            <span className="thank-you-success__copy-label">{textCopy}</span>
            <IconCustom icon="content_copy" />
          </Button>
        </div>
      ) : null}
      <div className="thank-you-success__group">
        {textBeforeBtn ? <DangerouslySetInnerHtml html={textBeforeBtn} /> : null}
      </div>
      <div className="thank-you-success__group">
        {link?.length ? (
          <Button
            aria-label={ariaBuy}
            className="thank-you-success__cta"
            variant="success"
            href={link[0].url}
            target={link[0].target}
          >
            {link[0].name}
            {logo.url ? (
              <img className="thank-you-success__cta-img" src={logo.url} alt={logoAlt} />
            ) : null}
          </Button>
        ) : null}
        {textAfterBtn ? <DangerouslySetInnerHtml html={textAfterBtn} /> : null}
      </div>
    </div>
  );
};

export default ThankYouContent;
