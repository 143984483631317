import React, { FC, useState } from 'react';
import { useId } from 'react-id-generator';

import { ICheckBoxCustom } from './models';
import './CheckBoxCustom.scss';

const CheckBoxCustom: FC<ICheckBoxCustom> = ({ checked, children, isRequired, validationMsg }) => {
  const [dropId] = useId();
  const [isChecked, setChecked] = useState(checked);

  const handleChange = () => {
    setChecked(!isChecked);
  };

  return (
    <label className="dt-checkbox-custom" htmlFor={`dt-checkbox-custom-${dropId}`}>
      <input
        className="dt-checkbox-custom__input"
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        id={`dt-checkbox-custom-${dropId}`}
        required={isRequired}
      />
      <span className="dt-checkbox-custom__icon" />
      {children}
      {validationMsg && <div className="invalid-feedback">{validationMsg}</div>}
    </label>
  );
};

export default CheckBoxCustom;
